import Vue from 'vue'
import Vuex from 'vuex'
import {i18n} from "@/plugins/i18n";
import Cookies from "js-cookie";

Vue.use(Vuex)


export default new Vuex.Store({
    state: {
        isFormSent: false,
        nav: [
            {title: "home", link: "/"},
            {title: "faq", link: "/faq"},
            {title: "terms", link: "/terms-and-conditions"},
            {title: "contact us", link: "/contact-us"},
        ],
        modalList: [
            {
                logo: 'betonred.svg',
                linkLogIn: 'https://track.betmenaffiliates.com/login',
                linkSignUp: 'https://track.betmenaffiliates.com/signup/'
            },
        ],
        benefits: [
            {
                icon: "percent.svg",
                text: i18n.t("home-page.benefits.text1"),
                iconClass: "percentIcon",
                textClass: "percentText",
                slideBg: require('@/assets/images/percent-slide-bg.png')
            },
            {
                icon: "heart.svg",
                text: i18n.t("home-page.benefits.text2"),
                iconClass: "heartIcon",
                textClass: "heartText",
                slideBg: require('@/assets/images/heart-slide-bg.png')
            },
            {
                icon: "clock.svg",
                text: i18n.t("home-page.benefits.text3"),
                iconClass: "clockIcon",
                // textClass: "clockText",
                textClass:
                    Cookies.get('locale') === 'pl' ? "clockText clockText-pl" : "clockText" ||
                    Cookies.get('locale') === 'en' ? "clockText clockText-tr" : "clockText" ||
                    Cookies.get('locale') === 'tr' ? "clockText clockText-tr" : "clockText"
                ,
                slideBg: require('@/assets/images/clock-slide-bg.png')
            },
            {
                icon: "wallet.svg",
                text: i18n.t("home-page.benefits.text4"),
                iconClass: "walletIcon",
                textClass: "walletText",
                slideBg: require('@/assets/images/wallet-slide-bg.png')
            },
            {
                icon: "star.svg",
                text: i18n.t("home-page.benefits.text5"),
                iconClass: "starIcon",
                // textClass: "starText",
                textClass: Cookies.get('locale') === 'tr' ? "starText starText-tr" : "starText",
                slideBg: require('@/assets/images/star-slide-bg.png')
            },
            {
                icon: "bar.svg",
                text: i18n.t("home-page.benefits.text6"),
                iconClass: "barIcon",
                // textClass: "barText",
                textClass: Cookies.get('locale') === 'tr' ? "barText barText-tr" : "barText",
                slideBg: require('@/assets/images/bar-slide-bg.png')
            },
        ],
        brands: [
            {
                logo: "betonred.svg",
                logoMob: "betonred-mob.svg",
                link: "https://betonred.com/",
                topCountries: [
                    {flag: "CA.svg", name: "CA"},
                    {flag: "PL.svg", name: "PL"},
                    {flag: "DE.svg", name: "DE"},
                    {flag: "TR.svg", name: "TR"},
                    {flag: "NG.svg", name: "NG"},
                ],
                welcomeBonus: i18n.t("home-page.brand.text1"),
                license: i18n.t("home-page.brand.text2"),
                games: i18n.t("home-page.brand.text3"),
            },
        ],
        workOrderList: [
            i18n.t("workOrderList[0]"),
            i18n.t("workOrderList[1]"),
            i18n.t("workOrderList[2]"),
        ],

        footerTopLinks: [
            {
                title: i18n.t('footer.caption1'),
                class: "home",
                links: [
                    {link: `/faq/${i18n.locale}`, title: i18n.t('faqList.title')},
                ],
            },
            {
                title: i18n.t('footer.caption2'),
                class: "contacts",
                links: [
                    {link: `/contact-us/${i18n.locale}`, title: i18n.t('contacts-page.title')},
                ],
                contacts: [
                    {contact: "mailto:mailto:hello@betmenaffiliates.com", title: "hello@betmenaffiliates.com"}
                ],
            },
            {
                title: i18n.t('footer.caption3'),
                class: "social",
                addClass: true,
                contacts: [
                    {contact: "https://www.facebook.com/BetmenAffiliates/", icon: require("@/assets/images/fb.svg")},
                    {
                        contact: "https://www.instagram.com/betmen_affiliates/",
                        icon: require("@/assets/images/inst.svg")
                    },
                    {contact: "https://www.linkedin.com/company/77642123/", icon: require("@/assets/images/in.svg")},
                ],
            },
        ],
        footerBottomLinks: [
            {link: "/cookie-policy", title: "Cookie Policy"},
            {link: "/privacy-policy", title: "Privacy Policy"},
            {link: "/terms-and-conditions", title: "Terms & Conditions"},
        ],

        termsConditions: [
            {
                text: [
                    i18n.t("termsConditions.text[0]"),
                    i18n.t("termsConditions.text[1]"),
                    i18n.t("termsConditions.text[2]")
                ],
                lists: [
                    {
                        title: i18n.t("termsConditions.lists[0].title"),
                        list: [
                            i18n.t("termsConditions.lists[0].list[0]"),
                            i18n.t("termsConditions.lists[0].list[1]"),
                            i18n.t("termsConditions.lists[0].list[2]"),
                            i18n.t("termsConditions.lists[0].list[3]"),
                            i18n.t("termsConditions.lists[0].list[4]"),
                            i18n.t("termsConditions.lists[0].list[5]"),
                            i18n.t("termsConditions.lists[0].list[6]"),
                            i18n.t("termsConditions.lists[0].list[7]"),
                            i18n.t("termsConditions.lists[0].list[8]"),
                            i18n.t("termsConditions.lists[0].list[9]"),
                            i18n.t("termsConditions.lists[0].list[10]"),
                            i18n.t("termsConditions.lists[0].list[11]"),
                            i18n.t("termsConditions.lists[0].list[12]"),
                            i18n.t("termsConditions.lists[0].list[13]"),
                            i18n.t("termsConditions.lists[0].list[14]"),
                            i18n.t("termsConditions.lists[0].list[15]"),
                            i18n.t("termsConditions.lists[0].list[16]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[1].title"),
                        list: [
                            i18n.t("termsConditions.lists[1].list[0]"),
                            i18n.t("termsConditions.lists[1].list[1]"),
                            i18n.t("termsConditions.lists[1].list[2]"),
                            i18n.t("termsConditions.lists[1].list[3]"),
                            i18n.t("termsConditions.lists[1].list[4]"),
                            i18n.t("termsConditions.lists[1].list[5]"),
                            i18n.t("termsConditions.lists[1].list[6]"),
                            i18n.t("termsConditions.lists[1].list[7]"),
                            i18n.t("termsConditions.lists[1].list[8]"),
                            i18n.t("termsConditions.lists[1].list[9]"),
                            i18n.t("termsConditions.lists[1].list[10]"),
                            i18n.t("termsConditions.lists[1].list[11]"),
                            i18n.t("termsConditions.lists[1].list[12]"),
                            i18n.t("termsConditions.lists[1].list[13]"),
                            i18n.t("termsConditions.lists[1].list[14]"),
                            i18n.t("termsConditions.lists[1].list[15]"),
                            i18n.t("termsConditions.lists[1].list[16]"),
                            i18n.t("termsConditions.lists[1].list[17]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[2].title"),
                        list: [
                            i18n.t("termsConditions.lists[2].list[0]"),
                            i18n.t("termsConditions.lists[2].list[1]"),
                            i18n.t("termsConditions.lists[2].list[2]"),
                            i18n.t("termsConditions.lists[2].list[2]"),
                            i18n.t("termsConditions.lists[2].list[2]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[3].title"),
                        list: [
                            i18n.t("termsConditions.lists[3].list[0]"),
                            i18n.t("termsConditions.lists[3].list[1]"),
                            i18n.t("termsConditions.lists[3].list[2]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[4].title"),
                        text: [
                            i18n.t("termsConditions.lists[4].text[0]")
                        ],
                        listLetter: [
                            i18n.t("termsConditions.lists[4].listLetter[0]"),
                            i18n.t("termsConditions.lists[4].listLetter[1]"),
                            i18n.t("termsConditions.lists[4].listLetter[2]"),
                            i18n.t("termsConditions.lists[4].listLetter[3]"),
                            i18n.t("termsConditions.lists[4].listLetter[4]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[5].title"),
                        list: [
                            i18n.t("termsConditions.lists[5].list[0]"),
                            i18n.t("termsConditions.lists[5].list[1]"),
                            i18n.t("termsConditions.lists[5].list[2]"),
                            i18n.t("termsConditions.lists[5].list[3]"),
                            i18n.t("termsConditions.lists[5].list[4]"),
                            i18n.t("termsConditions.lists[5].list[5]"),
                            i18n.t("termsConditions.lists[5].list[6]"),
                            i18n.t("termsConditions.lists[5].list[7]"),
                            i18n.t("termsConditions.lists[5].list[8]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[6].title"),
                        text: [
                            i18n.t("termsConditions.lists[6].text[0]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[7].title"),
                        text: [
                            i18n.t("termsConditions.lists[7].text[0]"),
                            i18n.t("termsConditions.lists[7].text[1]"),
                            i18n.t("termsConditions.lists[7].text[2]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[8].title"),
                        list: [
                            i18n.t("termsConditions.lists[8].list[0]"),
                            i18n.t("termsConditions.lists[8].list[1]"),
                            i18n.t("termsConditions.lists[8].list[2]"),
                        ]
                    },
                    {
                        title: i18n.t("termsConditions.lists[9].title"),
                        list: [
                            i18n.t("termsConditions.lists[9].list[0]"),
                            i18n.t("termsConditions.lists[9].list[1]"),
                            i18n.t("termsConditions.lists[9].list[2]"),
                            i18n.t("termsConditions.lists[9].list[4]"),
                            i18n.t("termsConditions.lists[9].list[5]"),
                            i18n.t("termsConditions.lists[9].list[6]"),
                            i18n.t("termsConditions.lists[9].list[7]"),
                            i18n.t("termsConditions.lists[9].list[8]"),
                        ]
                    },
                ]
            }
        ],

        privacyPolicy: [
            {
                text: [
                    i18n.t("privacyPolicy.text[0]"),
                    i18n.t("privacyPolicy.text[1]"),
                    i18n.t("privacyPolicy.text[2]"),
                ],
                lists: [
                    {
                        title: i18n.t("privacyPolicy.lists[0].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[0].text[0]")
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[1].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[1].text[0]")
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[2].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[2].text[0]")
                        ],
                        listDisc: [
                            i18n.t("privacyPolicy.lists[2].listDisc[0]"),
                            i18n.t("privacyPolicy.lists[2].listDisc[1]"),
                            i18n.t("privacyPolicy.lists[2].listDisc[2]"),
                            i18n.t("privacyPolicy.lists[2].listDisc[3]"),
                            i18n.t("privacyPolicy.lists[2].listDisc[4]"),
                            i18n.t("privacyPolicy.lists[2].listDisc[5]")
                        ]
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[3].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[3].text[0]")
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[4].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[4].text[0]")
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[5].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[5].text[0]")
                        ],
                        listDisc: [
                            i18n.t("privacyPolicy.lists[5].listDisc[0]"),
                            i18n.t("privacyPolicy.lists[5].listDisc[1]"),
                            i18n.t("privacyPolicy.lists[5].listDisc[2]"),
                            i18n.t("privacyPolicy.lists[5].listDisc[3]"),
                            i18n.t("privacyPolicy.lists[5].listDisc[4]"),
                        ],
                        text1: [
                            i18n.t("privacyPolicy.lists[5].text1[0]"),
                        ]
                    },

                    {
                        title: i18n.t("privacyPolicy.lists[6].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[6].text[0]")
                        ],
                        listDisc: [
                            i18n.t("privacyPolicy.lists[6].listDisc[0]"),
                            i18n.t("privacyPolicy.lists[6].listDisc[1]"),
                            i18n.t("privacyPolicy.lists[6].listDisc[2]"),
                            i18n.t("privacyPolicy.lists[6].listDisc[3]"),
                            i18n.t("privacyPolicy.lists[6].listDisc[4]"),
                            i18n.t("privacyPolicy.lists[6].listDisc[5]"),
                        ],
                        text1: [
                            i18n.t("privacyPolicy.lists[6].text1[0]"),
                            i18n.t("privacyPolicy.lists[6].text1[1]"),
                        ]
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[7].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[7].text[0]")
                        ],
                        listDisc: [
                            i18n.t("privacyPolicy.lists[7].listDisc[0]"),
                            i18n.t("privacyPolicy.lists[7].listDisc[1]"),
                            i18n.t("privacyPolicy.lists[7].listDisc[2]"),
                        ],
                        text1: [
                            i18n.t("privacyPolicy.lists[7].text1[0]"),
                            i18n.t("privacyPolicy.lists[7].text1[1]"),
                        ],
                        listDisc1: [
                            i18n.t("privacyPolicy.lists[7].listDisc1[0]"),
                            i18n.t("privacyPolicy.lists[7].listDisc1[1]"),
                            i18n.t("privacyPolicy.lists[7].listDisc1[2]"),
                        ],
                        text2: [
                            i18n.t("privacyPolicy.lists[7].text2[0]"),
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[8].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[8].text[0]")
                        ],
                        listDisc: [
                            i18n.t("privacyPolicy.lists[8].listDisc[0]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[1]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[2]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[3]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[4]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[5]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[6]"),
                            i18n.t("privacyPolicy.lists[8].listDisc[7]"),
                        ],
                        text1: [
                            i18n.t("privacyPolicy.lists[8].text1[0]"),
                        ]
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[9].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[9].text[0]"),
                            i18n.t("privacyPolicy.lists[9].text[1]")
                        ],
                    },
                    {
                        title: i18n.t("privacyPolicy.lists[10].title"),
                        text: [
                            i18n.t("privacyPolicy.lists[10].text[0]"),
                        ],
                    },
                ]
            }
        ],
        cookiePolicy: [
            {
                lists: [
                    {
                        title: i18n.t("cookiePolicy.lists[0].title"),
                        text: [
                            i18n.t("cookiePolicy.lists[0].text[0]")
                        ],
                    },
                    {
                        title: i18n.t("cookiePolicy.lists[1].title"),
                        text: [
                            i18n.t("cookiePolicy.lists[1].text[0]"),
                            i18n.t("cookiePolicy.lists[1].text[1]"),
                            i18n.t("cookiePolicy.lists[1].text[2]")
                        ],
                        listDisc: [
                            i18n.t("cookiePolicy.lists[1].listDisc[0]"),
                            i18n.t("cookiePolicy.lists[1].listDisc[1]"),
                            i18n.t("cookiePolicy.lists[1].listDisc[2]"),
                            i18n.t("cookiePolicy.lists[1].listDisc[3]"),
                            i18n.t("cookiePolicy.lists[1].listDisc[4]"),
                        ],
                        text1: [
                            i18n.t("cookiePolicy.lists[1].text1[0]"),
                            i18n.t("cookiePolicy.lists[1].text1[1]"),
                        ]
                    },
                    {
                        title: i18n.t("cookiePolicy.lists[2].title"),
                        text: [
                            i18n.t("cookiePolicy.lists[2].text[0]"),
                            i18n.t("cookiePolicy.lists[2].text[1]")
                        ],
                    },
                ]
            }
        ],
        faqList: {
            offers: {
                title: i18n.t("faqList.offers.title"),
                items: [
                    {
                        title: i18n.t("faqList.offers.items.title1"),
                        answer: i18n.t("faqList.offers.items.answer1")
                    },
                    {
                        title: i18n.t("faqList.offers.items.title2"),
                        answer: i18n.t("faqList.offers.items.answer2")
                    },
                    {
                        title: i18n.t("faqList.offers.items.title3"),
                        answer: i18n.t("faqList.offers.items.answer3")
                    },
                ]
            },
            general: {
                title: i18n.t("faqList.general.title"),
                items: [
                    {
                        title: i18n.t("faqList.general.items.title1"),
                        answer: i18n.t("faqList.general.items.answer1"),
                    },
                    {
                        title: i18n.t("faqList.general.items.title2"),
                        answer: i18n.t("faqList.general.items.answer2"),
                    },
                    {
                        title: i18n.t("faqList.general.items.title3"),
                        answer: i18n.t("faqList.general.items.answer3"),
                    },
                    {
                        title: i18n.t("faqList.general.items.title4"),
                        answer: i18n.t("faqList.general.items.answer4"),
                    },
                    {
                        title: i18n.t("faqList.general.items.title5"),
                        answer: i18n.t("faqList.general.items.answer5"),
                    },
                    {
                        title: i18n.t("faqList.general.items.title6"),
                        answer: i18n.t("faqList.general.items.answer6"),
                    },
                ]
            },
            payments: {
                title: i18n.t("faqList.payments.title"),
                items: [
                    {
                        title: i18n.t("faqList.payments.items[0].title"),
                        answer: i18n.t("faqList.payments.items[0].answer")
                    },
                    {
                        title: i18n.t("faqList.payments.items[1].title"),
                        answer: i18n.t("faqList.payments.items[1].answer")
                    },
                    {
                        title: i18n.t("faqList.payments.items[2].title"),
                        answer: i18n.t("faqList.payments.items[2].answer")
                    },
                    {
                        title: i18n.t("faqList.payments.items[3].title"),
                        answer: i18n.t("faqList.payments.items[3].answer")
                    },
                ]
            },
        },
        testimonials: [
            {
                logo: "online-casino.svg",
                href: "https://online.casino/",
                text: i18n.t("home-page.testimonials.text1"),
            },
            {
                logo: "handy-casinos.svg",
                href: "https://handycasinos24.com/",
                text: i18n.t("home-page.testimonials.text4"),
            },
            {
                logo: "spicycasino.png",
                href: "https://www.spicycasinos.com/",
                text: i18n.t("testimonials[5].text"),
            },
            {
                logo: "slot-catalog.png",
                href: "https://slotcatalog.com/en",
                text: i18n.t("testimonials[6].text"),
            },
            {
                logo: "play-fortune.png",
                href: "https://play-fortune.pl/",
                text: i18n.t("testimonials[7].text"),
            },
            {
                logo: "online-dollar-casino.svg",
                href: "https://www.onlinecasinodollar.com/",
                text: i18n.t("testimonials[8].text"),
            },
            {
                logo: "bonus-maniac.png",
                href: "https://bonusmaniac.com/",
                text: i18n.t("testimonials[9].text"),
            },
            {
                logo: "gamble-rush.png",
                href: "https://gamblerush.com/",
                text: i18n.t("testimonials[10].text"),
            },
            {
                logo: "allgame-casinos.png",
                href: "https://www.allgemcasinos.com/",
                text: i18n.t("testimonials[11].text"),
                width: 30,
            },
            {
                logo: "casino-leader.png",
                href: "https://www.casinoleader.com/reviews/betonred",
                text: i18n.t("testimonials[12].text"),
            },
            {
                logo: "tips-gg.png",
                href: "https://tips.gg/",
                text: i18n.t("testimonials[13].text"),
                width: 30,
            },
            {
                logo: "swiss-casino.png",
                href: "https://onlineswisscasino.com/",
                text: i18n.t("testimonials[14].text"),
            },
            {
                logo: "zamsino.png",
                href: "https://zamsino.com/ca/free-spins-no-deposit/",
                text: i18n.t("testimonials[15].text"),
            },
            {
                logo: "casinotypist.svg",
                href: "https://www.casinotypist.com/",
                text: i18n.t("testimonials[16].text"),
            },
            {
                logo: "primary.png",
                href: "https://wheregamble.com/",
                text: i18n.t("testimonials[17].text"),
            },
            {
                logo: "askgamblers.svg",
                href: "https://www.askgamblers.com/online-casinos/reviews/betonred-casino",
                text: i18n.t("testimonials[18].text"),
            },
            {
              logo: "kasynainternetowe.svg",
              href: "https://kasynainternetowe23.net/",
              text: i18n.t("testimonials[19].text"),
            },
            {
              logo: "opinie.svg",
              href: "https://opinie.com/",
              text: i18n.t("testimonials[20].text"),
            },
            {
              logo: "B-SAD-Logo.png",
              href: "https://osbelenensessad.com/",
              text: i18n.t("testimonials[21].text"),
            },
            {
                logo: "casino-apps.png",
                href: "http://casino-apps.net",
                text: i18n.t("testimonials[22].text"),
            },
            {
                logo: "bonusfanatic.png",
                href: "https://bonusfanatic.com/",
                text: i18n.t("testimonials[23].text"),
            },
            {
                logo: "supercasinosites.png",
                href: "https://www.supercasinosites.com/casino-apps/betonred-casino-mobile-app/",
                text: i18n.t("testimonials[24].text"),
            },
            {
                logo: "aboutslots.png",
                href: "https://www.aboutslots.com/",
                text: i18n.t("testimonials[25].text"),
            },
            {
                logo: "leadchiefs.svg",
                href: "https://www.leadchiefs.com",
                text: i18n.t("testimonials[26].text"),
            },
            {
                logo: "nodeposittracker.png",
                href: "https://www.nodeposittracker.com/",
                text: i18n.t("testimonials[27].text"),
            },
            {
                logo: "nodepositguide.png",
                href: "https://www.nodeposit.guide/",
                text: i18n.t("testimonials[28].text"),
            },
            {
                logo: "nodeposithero.png",
                href: "https://www.nodeposithero.com/",
                text: i18n.t("testimonials[29].text"),
            },
            {
                logo: "capitancasino.png",
                href: "https://capitancasino.net/",
                text: i18n.t("testimonials[30].text"),
            },
            {
                logo: "casinoalpha.png",
                href: "https://casinoalpha.co.nz/",
                text: i18n.t("testimonials[31].text"),
            },
            {
                logo: "freekasyno2.png",
                href: "https://freekasyno2.com/",
                text: i18n.t("testimonials[32].text"),
            },
            {
                logo: "onlineksyno.png",
                href: "https://www.onlineksyno.com/",
                text: i18n.t("testimonials[33].text"),
            },
            {
                logo: "betzest.png",
                href: "https://betzest.com/",
                text: i18n.t("testimonials[34].text"),
            },
            {
                logo: "casinoplusbonus.png",
                href: "https://casinoplusbonus.com/",
                text: i18n.t("testimonials[35].text"),
            },
            {
                logo: "gry-hazardowe-za-darmo.png",
                href: "https://gry-hazardowe-za-darmo.org/",
                text: i18n.t("testimonials[36].text"),
            },
            {
                logo: "askbonus.jpeg",
                href: "https://askbonus.com/",
                text: i18n.t("testimonials[37].text"),
            },
            {
                logo: "bojoko.png",
                href: "https://bojoko.ca/",
                text: i18n.t("testimonials[38].text"),
            },
            {
                logo: "polskie-kasynaonline-pl.png",
                href: "https://polskie.kasynaonline-pl.com/",
                text: i18n.t("testimonials[39].text"),
            },
            {
                logo: "time2play.svg",
                href: "https://time2play.com/",
                text: i18n.t("testimonials[41].text"),
            },
            {
                logo: "sloterman.png",
                href: "https://sloterman.bg/",
                text: i18n.t("testimonials[42].text"),
            },
            {
                logo: "play-no-deposit.svg",
                href: "https://playnodeposit.com/",
                text: i18n.t("testimonials[43].text"),
            },
            {
                logo: "nyerogep-777.webp",
                href: "https://kocsmainyerogepek.com/kaszino/betonred-casino/",
                text: i18n.t("testimonials[44].text"),
            },
            {
                logo: "GamblingORB-Greece.png",
                href: "https://gamblingorb-gr.com/",
                text: i18n.t("testimonials[45].text"),
            },
            {
                logo: "kasino-cs.png",
                href: "https://kasinocz.com/",
                text: i18n.t("testimonials[46].text"),
            },
            {
                logo: "bonuscasinostation.webp",
                href: "https://www.bonuscasinostation.com",
                text: i18n.t("testimonials[47].text"),
            },
            {
                logo: "betpack.svg",
                href: "https://www.betpack.com/",
                text: i18n.t("testimonials[48].text"),
            },
            {
                logo: "marmot.webp",
                href: "https://www.luckymarmot.com",
                text: i18n.t("testimonials[49].text"),
            },
            {
                logo: "czechcasinohub.png",
                href: "https://czechcasinohub.com/",
                text: i18n.t("testimonials[50].text"),
            },
            {
                logo: "novecasino.png",
                href: "https://novecasino.net/",
                text: i18n.t("testimonials[51].text"),
            },
            {
                logo: "gospincasino.png",
                href: "https://www.gospincasino.com",
                text: i18n.t("testimonials[52].text"),
            },
            {
                logo: "444win-logo.svg",
                href: "https://444.win/",
                text: i18n.t("testimonials[53].text"),
            },
            {
                logo: "online-casino.png",
                href: "https://online.casino/",
                text: i18n.t("testimonials[54].text"),
            },
            {
                logo: "casino-groups.png",
                href: "https://www.casino-groups.com/",
                text: i18n.t("testimonials[55].text"),
            },
            {
                logo: "igaming-pub.png",
                href: "https://www.igaming.pub/casinos/betonred-casino-review",
                text: i18n.t("testimonials[56].text"),
            },
            {
                logo: "pokiesmain.png",
                href: "https://pokiesman.com/",
                text: i18n.t("testimonials[57].text"),
            },
            {
                logo: "new-zealand.png",
                href: "https://www.nzcasinoclub.com/",
                text: i18n.t("testimonials[58].text"),
            },
            {
                logo: "nodepositfreund.png",
                href: "https://www.nodepositfreund.com/",
                text: i18n.t("testimonials[59].text"),
            },
            {
                logo: "atlantech.png",
                href: "https://atlantechcy.com",
                text: i18n.t("testimonials[60].text"),
            },
            {
                logo: "st.png",
                href: "https://www.spletnestave.com/",
                text: i18n.t("testimonials[61].text"),
            },
            {
                logo: "soc.png",
                href: "https://socioscasino.com/",
                text: i18n.t("testimonials[62].text"),
            },
            {
                logo: "slotozilla.png",
                href: "https://www.slotozilla-poland.com/pl",
                text: i18n.t("testimonials[63].text"),
            },
            {
                logo: "kasinobonus.png",
                href: "https://kasinobonus.io",
                text: i18n.t("testimonials[64].text"),
            },
            {
                logo: "casinobonusca.png",
                href: "https://casinobonusca.com",
                text: i18n.t("testimonials[65].text"),
            },
        ]
    },
    getters: {
        getIsFormSent(state) {
            return state.isFormSent
        },
        getNav(state) {
            return state.nav
        },
        getModalList(state) {
            return state.modalList
        },
        getBenefits(state) {
            return state.benefits
        },
        getBrands(state) {
            return state.brands
        },
        getWorkOrderList(state) {
            return state.workOrderList
        },
        getFooterTopLinks(state) {
            return state.footerTopLinks
        },

        getFooterBottomLinks(state) {
            return state.footerBottomLinks
        },
        getTermsConditions(state) {
            return state.termsConditions
        },
        getPrivacyPolicy(state) {
            return state.privacyPolicy
        },
        getCookiePolicy(state) {
            return state.cookiePolicy
        },
        getFaqList(state) {
            return state.faqList
        },
        getTestimonials(state) {
            return state.testimonials
        }
    },
    mutations: {
        SET_FORM_SENT(state, payload) {
            state.isFormSent = payload
        }
    },
    actions: {
        SET_FORM_SENT({commit}, payload) {
            commit('SET_FORM_SENT', payload)
        }
    },
    modules: {
        i18n, Cookies
    }
})
